var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VWrapper',{attrs:{"title":"option.index","sub-title":_vm.title}},[(_vm.$isLoading('option') || _vm.$isLoading('venue') || _vm.$isLoading('article') || _vm.$isLoading('tags'))?_c('EditOptionLoader'):_vm._e(),(
      !_vm.$isLoading('option') &&
      !_vm.$isLoading('article') &&
      !_vm.$isLoading('venue') &&
      !_vm.$isLoading('tags') &&
      (_vm.active || !_vm.editing)
    )?_c('div',[_c('VRow',{staticClass:"py-4"},[_c('VSpacer'),_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.activeOptionArticle = null;
          _vm.optionModalVisible = true;}}},[_vm._v(_vm._s(_vm.$t('common.add'))+" ")]),_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('common.save')))]),_c('VBtn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1),(!_vm.editing)?_c('VCard',{staticClass:"mt-2"},[_c('VCardText',[_c('OptionForm',{ref:"form",attrs:{"initial-values":_vm.option,"tags":_vm.tags}})],1)],1):_c('div',[_c('VTabs',{staticClass:"mt-2",attrs:{"vertical":_vm.$vuetify.breakpoint.mdAndUp,"center-active":"","show-arrows":"","align-with-title":""},on:{"touchstart":_vm.disableSwipe},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('VTab',[_vm._v(" "+_vm._s(_vm.$t('option.tabs.articles'))+" ")]),_c('VTab',[_vm._v(" "+_vm._s(_vm.$t('option.tabs.general'))+" ")]),_c('VTab',[_vm._v(" "+_vm._s(_vm.$t('option.tabs.availableHours'))+" ")]),_c('VTabsItems',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('VTabItem',{attrs:{"eager":""}},[_c('VDialog',{attrs:{"hide-overlay":"","fullscreen":""},model:{value:(_vm.optionModalVisible),callback:function ($$v) {_vm.optionModalVisible=$$v},expression:"optionModalVisible"}},[_c('VCard',[_c('VToolbar',{staticClass:"toolbar",attrs:{"flat":"","dark":"","color":"primary"}},[_c('VToolbarTitle',[_vm._v(_vm._s(_vm.$t(_vm.activeOptionArticle ? 'option.articles.editTitle' : 'option.articles.addTitle'))+" ")]),_c('VSpacer'),_c('VToolbarItems',[_c('VBtn',{attrs:{"text":""},on:{"click":_vm.saveOptionArticle}},[_vm._v(_vm._s(_vm.$t('common.save')))]),_c('VBtn',{attrs:{"text":""},on:{"click":function($event){_vm.optionModalVisible = false;
                        _vm.activeOptionArticle = null;}}},[_vm._v(_vm._s(_vm.$t('common.cancel'))+" ")])],1)],1),_c('VCardText',[(_vm.activeOptionArticle)?_c('OptionArticleForm',{ref:"editOption",attrs:{"articles":_vm.articles,"initial-values":_vm.activeOptionArticle,"tags":_vm.tags,"venue":_vm.venue,"printGroups":_vm.printGroups,"constrains":_vm.constrains}}):_c('OptionArticleForm',{ref:"createOption",attrs:{"articles":_vm.articles,"tags":_vm.tags,"venue":_vm.venue,"printGroups":_vm.printGroups,"constrains":_vm.constrains}})],1)],1)],1),_c('OptionArticleTable',{attrs:{"items":_vm.optionArticles,"option":_vm.option,"categories":_vm.categories,"tags":_vm.tags},on:{"edit":function($event){_vm.activeOptionArticle = $event;
                _vm.optionModalVisible = true;},"search":_vm.onSearch,"category":_vm.onCategoryChange,"delete":function($event){return _vm.deleteOptionArticle({ id: $event._id, group: _vm.active._id })},"visibility":_vm.toggleVisibility,"edited":_vm.updateProp,"editedExternalOrderProvider":_vm.updateExternalOrderProvider,"activation":_vm.toggleActivation}})],1),_c('VTabItem',{attrs:{"eager":""}},[_c('OptionForm',{ref:"form",attrs:{"initial-values":_vm.option,"tags":_vm.tags}})],1),_c('VTabItem',{attrs:{"eager":""}},[_c('VRow',[_c('VCol',{attrs:{"col":"12"}},[_c('VCard',[_c('VCardText',[_c('AvailableHourForm',{ref:"availableHours",attrs:{"initial-values":_vm.option}})],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }